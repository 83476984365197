import React, { Fragment } from "react";
import profileImg from "../../assets/img/faces/amitx.jpg";
import myCV from "../../assets/assetsV2/docs/amitCv.pdf";

const AboutMe = () => {
  return (
    <Fragment>
      <div className="about-header">
        <div className="title-small">
          <p>ABOUT ME</p>
        </div>
      </div>
      <div className="content-container">
        <div className="display-table">
          <div className="display-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-5 col-md-12">
                  <div className="about-img">
                    <img
                      src={profileImg}
                      alt="Amit"
                      className={"img-responsive"}
                    />
                    {/* <div className="img-hover">
                      <a href={myCV} target={"_blank"}>
                        Download my CV
                      </a>
                    </div> */}
                    {/* <div className="img-overlay" /> */}
                  </div>
                </div>
                <div className="col-lg-7 col-md-12">
                  <div className="about-content">
                    <h4 className="section-title">
                      Hello! I am Amit Panigrahi
                    </h4>
                    <p className="about-text m-0">
                      The true success is the person who invented himself.
                    </p>
                    <p className="about-text mb-1">
                      Darling, you are a work of art and each day is providing
                      its own gifts.
                    </p>
                    <div className="skills">
                      <div className="progressbar-area">
                        <h6>Html & CSS</h6>
                        <div className="progress">
                          <div
                            className="progress-bar prog1"
                            role="progressbar"
                            aria-valuenow="77"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-progress="77%"
                          >
                            <span>77%</span>
                          </div>
                        </div>
                        <h6>JavaScript</h6>
                        <div className="progress">
                          <div
                            className="progress-bar prog2"
                            role="progressbar"
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-progress="80%"
                          >
                            <span>80%</span>
                          </div>
                        </div>
                        <h6>React Js</h6>
                        <div className="progress">
                          <div
                            className="progress-bar prog3"
                            role="progressbar"
                            aria-valuenow="82"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-progress="82%"
                          >
                            <span>82%</span>
                          </div>
                        </div>
                        <h6>Python 2.7 & 3.6 with Django</h6>
                        <div className="progress">
                          <div
                            className="progress-bar prog3"
                            role="progressbar"
                            aria-valuenow="72"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-progress="72%"
                          >
                            <span>72%</span>
                          </div>
                        </div>
                        <h6>PostgreSQL</h6>
                        <div className="progress">
                          <div
                            className="progress-bar prog3"
                            role="progressbar"
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            data-progress="75%"
                          >
                            <span>75%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AboutMe;
