export const contactData = {
  phone: {
    text: "",
    link: ""
  },
  email: {
    text: "amitbizweb@gmail.com",
    link: "amitbizweb@gmail.com"
  },
  website: {
    text: "piratewit.com",
    link: "https://piratewit.com/"
  },
  address: {
    text: "Mumbai, India",
    link: ""
  }
};
