import React from "react";

const MyResume = () => {
  return (
    <div className="content-container">
      <div className="display-table">
        <div className="display-content">
          <div className="resume-header">
            <div className="title-small">
              <p>MY TIMELINE</p>
            </div>
            <h4 className="section-title">Short Timeline</h4>
          </div>
          <div className="resume-content">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="education">
                    <div className="resume-item">
                      <p className="res-year">2011-2013</p>
                      <p className="res-title">CHSE</p>
                      <p>
                        Undergone 2 years of higher secondary eduction in
                        science with IT stream.
                      </p>
                    </div>
                    <div className="resume-item">
                      <p className="res-year">2013-2017</p>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <p className="res-title">Bachelor - S'O'A University</p>
                      <p>
                        Completed complete 4 years of my bachelors with with
                        with Electrical and Electronics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="experience">
                    <div className="resume-item">
                      <p className="res-year">2017-2019</p>
                      <p className="res-title">Full Stack Developer</p>
                      <p>
                        Started my carrier as a Full Stack Developer, holding
                        Python in backend.
                      </p>
                    </div>
                    <div className="resume-item">
                      <p className="res-year">2019-Current</p>
                      <p className="res-title">React Js Developer</p>
                      <p>
                        Started working at client location at a fast paced to
                        launch the product with a tight deadline.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyResume;
