import React, { PureComponent } from "react";
import portImg1 from "../../assets/assetsV2/images/portfolio/img1.jpg";
import portImg2 from "../../assets/assetsV2/images/portfolio/img2.jpg";
import portImg3 from "../../assets/assetsV2/images/portfolio/img3.jpg";
import shriGanesha from "../../assets/assetsV2/images/client/shriGanesha.jpg";
import maaDurga from "../../assets/assetsV2/images/client/maaDurga.jpeg";
import jaiHanuman from "../../assets/assetsV2/images/client/jaiHanuman.jpeg";

class ClientTestimonial extends PureComponent {
  render() {
    return (
      <div className="content-container">
        <div className="display-table">
          <div className="display-content">
            <div className="testimonial-header">
              <div className="title-small">
                <p>TESTIMONIAL</p>
              </div>
              <h4 className="section-title">What People Say</h4>
            </div>
            <div className="client-area">
              <div className="container">
                <div className="row">
                  <div
                    id="owl-demo-testimonial"
                    className="owl-carousel owl-theme"
                  >
                    <div className="item">
                      <div className="client-img">
                        <img src={shriGanesha} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">Shri Ganesha</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="client-img">
                        <img src={jaiHanuman} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">Jay Hanuman</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="client-img">
                        <img src={portImg2} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">N. Panda</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="client-img">
                        <img src={portImg3} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">N.K. Panigrahi</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="client-img">
                        <img src={portImg1} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">P. Panigrahi</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="client-img">
                        <img src={maaDurga} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">Maa Durga</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="item">
                      <div className="client-img">
                        <img src={portImg3} alt="client" />
                      </div>
                      <div className="testimonial-content">
                        <div className="quote-text">
                          <p>
                            <i
                              className="fa fa-quote-left"
                              aria-hidden="true"
                            />
                            <span>
                              Facilisis eu iaculis suspendisse congue auctor
                              parturient consectetur nec gravida parturient.
                            </span>
                            <i
                              className="fa fa-quote-right"
                              aria-hidden="true"
                            />
                          </p>
                          <div className="client-identity">
                            <p className="name">Jai Jagannath</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientTestimonial;
