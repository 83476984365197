import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/globalStyle.scss";

import "assets/scss/material-kit-react.scss?v=1.8.0";

//Constants
import routesHandle from "./constants/routesHandle";

// pages for this product
import HomePage from "./views/HomePageV2";
import LoginFire from "./views/LoginFire";
import LoginPage from "./views/LoginPage/LoginPage";

let hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path={routesHandle["default"]["path"]} component={HomePage} />
      <Route path={routesHandle["login"]["path"]} component={LoginPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
