import React, { Fragment, PureComponent } from "react";
import brandLogo from "../../assets/img/brandLogo/brandLogoTr.png";

class HeaderModule extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className="logo-area">
          <a href={"/"} className="logo">
            <img width="100px" src={brandLogo} alt="logo" />
          </a>
        </div>
        <div className="header-info-area">
          <a
            className="info-btn"
            id="header-info-btn"
            href={"#header-info-bar"}
          >
            <span className="top" />
            <span className="middle" />
            <span className="bottom" />
          </a>
          <div id="header-info-bar">
            <div className="close-animatedModal close-header-info-bar">
              <i className="fas fa-times" />
            </div>
            <div className="modal-content">
              <nav className="menu" id="menu">
                <ul id="mainMenu">
                  <li data-menuanchor="home" className="active">
                    <a href={"#home"}>Home</a>
                  </li>
                  <li data-menuanchor="about">
                    <a href={"#about"}>About</a>
                  </li>
                  <li data-menuanchor="service">
                    <a href={"#service"}>Services</a>
                  </li>
                  <li data-menuanchor="timeline">
                    <a href={"#timeline"}>Timeline</a>
                  </li>
                  {/*<li data-menuanchor="portfolio">*/}
                  {/*  <a href={"#portfolio"}>Portfolio</a>*/}
                  {/*</li>*/}
                  <li data-menuanchor="work">
                    <a href={"#work"}>Work</a>
                  </li>
                  {/*<li data-menuanchor="client-testimonial">*/}
                  {/*  <a href={"#client-testimonial"}>Testimonial</a>*/}
                  {/*</li>*/}
                  {/*<li data-menuanchor="blog">*/}
                  {/*  <a href={"#blog"}>Blog</a>*/}
                  {/*</li>*/}
                  <li data-menuanchor="contact">
                    <a href={"#contact"}>Contact</a>
                  </li>
                </ul>
              </nav>
              {/*<div className="newsletter" id="newsletter">*/}
              {/*  <div className="container">*/}
              {/*    <div className="row">*/}
              {/*      <h5>Get Updates:</h5>*/}
              {/*      <div className="newsletter-form">*/}
              {/*        <form id="mc-form">*/}
              {/*          <input*/}
              {/*            type="email"*/}
              {/*            id="mc-email"*/}
              {/*            className="newsletter-email"*/}
              {/*            placeholder="Type your email here"*/}
              {/*          />*/}
              {/*          <button type="submit" className="submit-btn">*/}
              {/*            <span>Subscribe</span>*/}
              {/*          </button>*/}
              {/*          <label htmlFor="mc-email" />*/}
              {/*        </form>*/}
              {/*      </div>*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default HeaderModule;
