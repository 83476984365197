import React from "react";

const WorkControl = () => {
  return (
    <div className="content-container">
      <div className="display-table">
        <div className="display-content">
          <div className="work-header">
            <div className="title-small">
              <p>How I Work</p>
            </div>
            <h4 className="section-title">How I Work</h4>
          </div>
          <div className="work-content">
            <div className="container">
              <div className="row">
                {/*<div className="col-md-6">*/}
                {/*  <div className="video-container">*/}
                {/*    <div className="video-area">*/}
                {/*      <a*/}
                {/*        href={"https://www.youtube.com/watch?v=EfTUpvxEbqc"}*/}
                {/*        className="video-play button"*/}
                {/*//         target="_self"*/}
                {/*//       >*/}
                {/*        <div className="video-icon">*/}
                {/*          <span>*/}
                {/*            <i className="fas fa-caret-right" />*/}
                {/*          </span>*/}
                {/*        </div>*/}
                {/*      </a>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="col-md-12">
                  <div className="work-item">
                    <span className="work-title">Discuss &amp; Plan</span>
                    <p>
                      Discuss with peers to find out the execution plan and
                      timeline.
                    </p>
                  </div>
                  <div className="work-arrow" />
                  <div className="work-item">
                    <span className="work-title">Design &amp; Develop</span>
                    <p>
                      Do as much research in this phase to determine the
                      resource and the stack requirements for the flow of the
                      project.
                    </p>
                  </div>
                  <div className="work-arrow" />
                  <div className="work-item">
                    <span className="work-title">Final Approach</span>
                    <p>
                      Its the time to start the execution of the plans
                      step-by-step.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkControl;
