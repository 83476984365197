import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Text } from "rebass";
import firebase from "../firebaseConfig";
class Login extends Component {
  state = {
    email: "",
    password: "",
    error: null
  };
  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleSubmit = event => {
    event.preventDefault();
    const { email, password } = this.state;
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        this.props.history.push("/");
      })
      .catch(error => {
        this.setState({ error: error });
      });
  };
  render() {
    const { email, password, error } = this.state;
    return (
      <div>
        <div>
          <Box>
            <div>Log In</div>
          </Box>
        </div>
        {error ? (
          <div>
            <Box>
              <Text>{error.message}</Text>
            </Box>
          </div>
        ) : null}
        <div>
          <Box>
            <form onSubmit={this.handleSubmit}>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={email}
                onChange={this.handleInputChange}
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={password}
                onChange={this.handleInputChange}
              />
              <Button>Log In</Button>
            </form>
          </Box>
        </div>
      </div>
    );
  }
}
export default withRouter(Login);
