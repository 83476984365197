export const socialMediaHandles = {
  facebook: {
    link: "https://www.facebook.com/amitx01"
  },
  linkedin: {
    link: "https://www.linkedin.com/in/amit-panigrahi"
  },
  instagram: {
    link: "https://www.instagram.com/amitx.7"
  }
};

export const socialHandleList = [
  {
    handle: "facebook",
    id: "instagram-facebook",
    class: "fab fa-facebook-f",
    link: "https://www.facebook.com/amitx01"
  },
  {
    handle: "instagram",
    id: "instagram-tooltip",
    class: "fab fa-instagram",
    link: "https://www.instagram.com/amitx.7"
  },
  {
    handle: "linkedin",
    id: "linkedin",
    class: "fab fa-linkedin-in",
    link: "https://www.linkedin.com/in/amit-panigrahi"
  }
];
