import routesHandle from "../constants/routesHandle";
import { socialHandleList } from "../constants/socialMediaHandle";
import React from "react";

export const getPathFromConstant = param => {
  if (!Object.getOwnPropertyDescriptor(routesHandle, param)) {
    return routesHandle.default.path;
  }
  return routesHandle[param]["path"];
};

export const renderSocialMediaLinks = (
  customList = [],
  classList = "",
  target = "_blank"
) => {
  let mapList = socialHandleList;
  if (customList.length) {
    mapList = customList;
  }
  return mapList.map((value, index) => (
    <a
      className={classList}
      key={index}
      href={value["link"] || "#"}
      target={target}
    >
      <i className={value["class"]} />
    </a>
  ));
};
