const routesHandle = {
  default: {
    path: "/"
  },
  profile: {
    path: "/profile"
  },
  myStory: {
    path: "/story"
  },
  login: {
    path: "/login"
  },
  blog: {
    path: "/blog"
  }
};

export default routesHandle;
