import React, { PureComponent } from "react";
import styled from "styled-components";

//Static CSS files
import "../../assets/assetsV2/css/magnific-popup.css";
import "../../assets/assetsV2/css/lightbox.css";
import "../../assets/assetsV2/css/jquery.pagepiling.min.css";
import "../../assets/assetsV2/css/owl.theme.default.css";
import "../../assets/assetsV2/css/owl.carousel.min.css";
import "../../assets/assetsV2/css/reset.css";
import "../../assets/assetsV2/css/style.css";
import "../../assets/assetsV2/css/responsive.css";

//Static Images
import HeaderModule from "../../componentsV2/Header";
import SnowScreen from "../../componentsV2/WelcomeScreen-scr1/snowScreen";
import AboutMe from "../../componentsV2/AboutMe";
import Services from "../../componentsV2/Services";
import MyResume from "../../componentsV2/MyResume-scr4";
import MyPortfolio from "../../componentsV2/MyPortfolio";
import WorkControl from "../../componentsV2/WorkControl";
import ClientTestimonial from "../../componentsV2/ClientTestimonial";
import ContactContainer from "../../componentsV2/ContactModule";
import BlogContainer from "../../componentsV2/BlogContainer";

const MainContainer = styled.div`
  .pp-scrollable {
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-image: -webkit-gradient(
        linear,
        left bottom,
        left top,
        color-stop(0.44, rgb(122, 153, 217)),
        color-stop(0.72, rgb(73, 125, 189)),
        color-stop(0.86, rgb(28, 58, 148))
      );
    }
  }
`;

const CustomScrollContainer = styled.div`
  position: fixed;
  width: 100%;
  z-index: 10;
  bottom: 75px;
  text-align: center;
  a {
    color: #ff5733;
    font: normal 400 12px/1 "Josefin Sans", sans-serif;
    letter-spacing: 0.1em;
    text-decoration: none;
    transition: opacity 0.3s;
  }
  .animated-scr {
    cursor: pointer;
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    left: 50%;
    width: 25px;
    height: 40px;
    margin-left: -15px;
    border: 2px solid #ff5733;
    border-radius: 50px;
    box-sizing: border-box;
    &::before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: "";
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #ff5733;
      border-radius: 100%;
      -webkit-animation: sdb10 2s infinite;
      animation: sdb10 2s infinite;
      box-sizing: border-box;
    }
    &::after {
      content: "Click to Scroll";
      color: #ff5733;
      position: absolute;
      bottom: -30px;
      width: 110px;
      left: -40px;
    }
  }
  @-webkit-keyframes sdb10 {
    0% {
      -webkit-transform: translate(0, 20px);
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    80% {
      -webkit-transform: translate(0, 0);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes sdb10 {
    0% {
      transform: translate(0, 20px);
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    80% {
      transform: translate(0, 0);
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
`;

class HomePageContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pathName: "",
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.updateIndex, false);
    this.updateIndex();
  }

  componentWillUnmount() {
    this.updateIndex();
  }

  componentDidUpdate() {
    this.updateIndex();
  }

  updateIndex = () => {
    const {
      // eslint-disable-next-line react/prop-types
      location: { hash }
    } = this.props;
    const pathNames = [
      "#home",
      "#about",
      "#service",
      "#timeline",
      "#work",
      // "#client-testimonial",
      // "#blog",
      "#contact"
    ];
    const in1 = pathNames.indexOf(hash);
    if (in1 >= 0) {
      this.setState({
        pathName: pathNames[in1 + 1 !== pathNames.length ? in1 + 1 : 0]
      });
    } else {
      this.setState({ pathName: pathNames[1] });
    }
  };

  render() {
    return (
      <MainContainer>
        <div id="pagepiling" className="imgBg">
          {/*<div className="preloader">*/}
          {/*  <div className="preloader-img" />*/}
          {/*</div>*/}
          {/*Header Component*/}
          <header>
            <HeaderModule />
          </header>

          {/*Welcome scr-1*/}
          <section
            className="welcome-area home animated-text section pp-scrollable"
            id="welcome-area"
          >
            <SnowScreen />
          </section>

          {/*About scr-2*/}
          <section className="about section pp-scrollable" id="about">
            <AboutMe />
          </section>

          {/*Service scr-3*/}
          <section className="service section pp-scrollable" id="service">
            <Services />
          </section>

          {/*Resume Desc scr-4*/}
          <section className="resume section pp-scrollable" id="resume">
            <MyResume />
          </section>

          {/*Portfolio scr-5*/}
          {/*<section className="portfolio section pp-scrollable" id="portfolio">*/}
          {/*  <MyPortfolio />*/}
          {/*</section>*/}

          {/*WorkControl scr-6*/}
          <section className="work section pp-scrollable" id="work">
            <WorkControl />
          </section>

          {/*Testimonial scr-7*/}
          {/*<section*/}
          {/*  className="client-testimonial pp-scrollable section"*/}
          {/*  id="client-testimonial"*/}
          {/*>*/}
          {/*  <ClientTestimonial />*/}
          {/*</section>*/}

          {/*Blog Container scr-8*/}
          {/*<section className="blog pp-scrollable section" id="blog">*/}
          {/*  <BlogContainer />*/}
          {/*</section>*/}

          {/*ContactContainer*/}
          <section
            className="contact footer pp-scrollable section"
            id="contact"
          >
            <div className="contact-header">
              <div className="title-small">
                <p>CONTACT ME</p>
              </div>
            </div>
            <ContactContainer />
          </section>

          <CustomScrollContainer id="section-scroll" className="demo">
            <a onClick={() => this.updateIndex()} href={this.state.pathName}>
              <span className={"animated-scr"} />
            </a>
          </CustomScrollContainer>
        </div>
      </MainContainer>
    );
  }
}

export default HomePageContainer;
