import React from "react";

const Services = () => {
  return (
    <div className="content-container">
      <div className="display-table">
        <div className="display-content">
          <div className="service-header">
            <div className="title-small">
              <p>My SERVICES</p>
            </div>
            <h4 className="section-title">What I Offer</h4>
          </div>
          <div className="service-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-item">
                    <span>
                      <i className="fas fa-lightbulb" />
                    </span>
                    <p className="content-title">UNIQUE IDEAS</p>
                    <p className="content-text">
                      Creative in nature and always eagerly work on creating new
                      ideas and implementing them.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-item">
                    <span>
                      <i className="fas fa-pencil-ruler" />
                    </span>
                    <p className="content-title">WEB DESIGN</p>
                    <p className="content-text">
                      Highly passionate in working on web designing with,
                      responsiveness keeping in mind.
                    </p>
                  </div>
                </div>
                {/*<div className="col-lg-4 col-md-6 col-sm-12">*/}
                {/*  <div className="service-item">*/}
                {/*    <span>*/}
                {/*      <i className="fas fa-laptop-code" />*/}
                {/*    </span>*/}
                {/*    <p className="content-title">THEME DEVELOPMENT</p>*/}
                {/*    <p className="content-text">*/}
                {/*      Work on developing beautiful themes for with, great Ui/UX*/}
                {/*      designs.*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="col-lg-4 col-md-6 col-sm-12">*/}
                {/*  <div className="service-item">*/}
                {/*    <span>*/}
                {/*      <i className="fas fa-pen-nib" />*/}
                {/*    </span>*/}
                {/*    <p className="content-title">CONTENT WRITING</p>*/}
                {/*    <p className="content-text">*/}
                {/*      Sed ut perspiciatis unde omnis iste natus error sit*/}
                {/*      voluptatem accusantium sit voluptatem accusantium*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</div>*/}
                {/*<div className="col-lg-4 col-md-6 col-sm-12">*/}
                {/*  <div className="service-item">*/}
                {/*    <span>*/}
                {/*      <i className="fas fa-headphones" />*/}
                {/*    </span>*/}
                {/*    <p className="content-title">24/7 SUPPORT</p>*/}
                {/*    <p className="content-text">*/}
                {/*      Sed ut perspiciatis unde omnis iste natus error sit*/}
                {/*      voluptatem accusantium sit voluptatem accusantium*/}
                {/*    </p>*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="service-item">
                    <span>
                      <i className="fas fa-hammer" />
                    </span>
                    <p className="content-title">EASY TO CUSTOMIZE</p>
                    <p className="content-text">
                      Code modularity is one of my top priority, which makes it
                      easier to reuse the components.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
