import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyAcuCijbKfBexUntHbFNAqX8w0iaa0uxU0",
  authDomain: "amitxonez.firebaseapp.com",
  databaseURL: "https://amitxonez.firebaseio.com",
  projectId: "amitxonez",
  storageBucket: "amitxonez.appspot.com",
  messagingSenderId: "785484047153",
  appId: "1:785484047153:web:a2f6f03e7f96e77a63c610"
};

firebase.initializeApp(firebaseConfig);
export default firebase;
