import React from "react";
import { renderSocialMediaLinks } from "../../methods/helper";
import Particles from "react-particles-js";
import { snowParticles } from "../../constants/snowParticles";

const SnowScreen = () => {
  return (
    <div className="snow-bg " id="welcome-image-area">
      <Particles
        id="particles-js"
        className={"particles-js"}
        {...snowParticles}
      />
      <div className="content-container">
        <div className="overlayImg" />
        <div className="title-small">
          <p>HOME</p>
        </div>
        <div className="display-table">
          <div className="display-content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="welcome-text">
                    <div className="type-wrap">
                      <div id="typed-strings">
                        <h1>
                          I&apos;m <span>Amit Panigrahi</span>
                        </h1>
                        <h1>
                          I&apos;m a <span>Web Developer</span>
                        </h1>
                      </div>
                      <span id="typed" />
                    </div>
                    <p>
                      Goal-oriented Web Developer with a strong commitment to
                      collaboration and solutions-oriented problem-solving. Use
                      various web design software to develop customer-focused
                      websites and designs. Committed to high standards of web
                      design, user experience, usability and speed for multiple
                      types of end-users. Successful at maintaining customer
                      satisfaction through effective customer support.
                    </p>
                    <div className="social-icons">
                      {renderSocialMediaLinks()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SnowScreen;
