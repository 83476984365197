import React, { PureComponent } from "react";
import redHeart from "../../assets/assetsV2/images/redHeart.svg";
import { renderSocialMediaLinks } from "../../methods/helper";
import { contactData } from "../../constants/addressConfig";

class ContactContainer extends PureComponent {
  render() {
    return (
      <div className="content-container">
        <div className="display-table">
          <div className="display-content">
            <div className="contact-header">
              <div className="title-small">
                <p>CONTACT ME</p>
              </div>
              <h4 className="section-title">Get In Touch</h4>
            </div>
            <div className="contact-content">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-6 col-md-12 col-sm-12 m-auto">
                    <div className="contact-detail">
                      <h6>Contact</h6>
                      <p className="contact-text">
                        Liked my profile or wanna catchup over a cup of Coffee!! Find me here.
                      </p>
                      <p className="adress">
                        <i className="fa fa-map-marker" aria-hidden="true" />
                        <span>Address:</span>
                        <span className="adress-loc">
                          {contactData["address"]["text"]}
                        </span>
                      </p>
                      {/*<p className="phone">*/}
                      {/*  <i className="fa fa-phone" aria-hidden="true" />*/}
                      {/*  <span>Contact no:</span>+999000000*/}
                      {/*</p>*/}
                      <p className="email">
                        <i className="fas fa-envelope" aria-hidden="true" />
                        <span>Email:</span>
                        {contactData["email"]["text"]}
                      </p>
                      <p>
                        <i className="fa fa-globe" aria-hidden="true" />
                        <span>Website:</span>
                        <a href={contactData["website"]["link"]}>
                          {contactData["website"]["text"]}
                        </a>
                      </p>
                      <div className="social-icons">
                        <p>Find:</p>
                        {renderSocialMediaLinks()}
                      </div>
                    </div>
                  </div>
                  {/*<div className="col-lg-6 col-md-12 col-sm-12">*/}
                  {/*  <div className="contact-form">*/}
                  {/*    <form*/}
                  {/*      className="contact-form-area"*/}
                  {/*      action={""}*/}
                  {/*      method="post"*/}
                  {/*    >*/}
                  {/*      <div className="row">*/}
                  {/*        <div className="col-md-6">*/}
                  {/*          <input*/}
                  {/*            type="text"*/}
                  {/*            name="name"*/}
                  {/*            placeholder="Name"*/}
                  {/*            id="form-name"*/}
                  {/*          />*/}
                  {/*        </div>*/}
                  {/*        <div className="col-md-6">*/}
                  {/*          <input*/}
                  {/*            type="text"*/}
                  {/*            name="email"*/}
                  {/*            placeholder="Email"*/}
                  {/*            id="form-email"*/}
                  {/*          />*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*      <div className="row">*/}
                  {/*        <div className="col-md-12">*/}
                  {/*          <input*/}
                  {/*            type="text"*/}
                  {/*            name="subject"*/}
                  {/*            placeholder="Subject"*/}
                  {/*            id="form-subject"*/}
                  {/*          />*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*      <div className="row">*/}
                  {/*        <div className="col-md-12">*/}
                  {/*          <textarea*/}
                  {/*            placeholder="Type here"*/}
                  {/*            name="message"*/}
                  {/*            id="form-message"*/}
                  {/*          />*/}
                  {/*          <input*/}
                  {/*            type="submit"*/}
                  {/*            className="submit-btn btn-style1 clearfix"*/}
                  {/*            value="Submit"*/}
                  {/*            id="contact-submit"*/}
                  {/*          />*/}
                  {/*          <div className="result" />*/}
                  {/*        </div>*/}
                  {/*      </div>*/}
                  {/*    </form>*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
            <footer>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="copyright">
                      <p>
                        © 2024 , made with{" "}
                        <span>
                          <img height={"20px"} alt={"Heart"} src={redHeart} />{" "}
                        </span>
                        by Amit Panigrahi for a better web.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactContainer;
